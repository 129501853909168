<template>
	<ul class="list list--full">
        <li v-for="(item, index) in computedList"
            :key="index">
            <div class="item">
                <router-link :to="`/rooms/${item.HOTEL_NO}`" class="list__link">
                    <!-- [D] 쿠폰이 2개 있을 시 -->
                    <div class="card__badge badge badge--coupon" v-if="item.CP_NM?.length > 0">
						<span class="badge__text">
                            {{item.CP_NM}}
                        </span>
                    </div>
                    <div class="list__wrap">
                        <div class="list__image">
                            <div class="list__imagebox noneimage">
                                <div class="imagebg" :style="{'background-image': 'url(\'' + item.IMG_URL + '\')'}" />
                            </div>
                            <!-- [D] 선착순 할인 쿠폰 있을시 -->
                            <!-- span class="badge__text purple">
                                선착순 할인쿠폰
                            </span -->
                        </div>
                        <div class="list__data">
                            <div class="list__box flex">
                                <div class="list__grad info-grad" v-if="item.REVIEWS && item.REVIEWS > 0">
                                <span class="list__icon info-icon">
                                    <i class="icon icon-star"></i>
                                </span>
                                    <span class="list__gradnum info-gradnum">
                                    {{item.SCORE.toFixed(1)}}
                                </span>
                                    <span class="list__gradcounter info-gradcounter">
                                    ({{item.REVIEWS}})
                                </span>
                                </div>
                            </div>
                            <h3 class="list__title info-title">
                               {{item.HOTEL_KNM}}
                            </h3>
                            <div class="list__slogan info-slogan">
                                {{item.mHOTEL_SLOGAN}}
                            </div>
                            <div class="list__distance info-distance">
                                {{item.mHOTEL_LOCTEXT}}
                            </div>
                            <div class="list__have info-have">
                               {{item.mHOTEL_SLOGAN2}}
                            </div>
                            
                            <div class="list__hashtag">
                                <span 
                                    v-for="(tag, index) in item.HOTEL_TAG"
                                    :key="index">
                                    {{tag + " "}}
                                </span>
                            </div>
                        </div>
                        <ul class="list__info" style="position:static; width:100%;">
                            <li v-if="item.D_SALES_YN === 'Y'">
                                <div class="list__info-type">
                                    <span class="list__badge">대실</span>
                                    {{item.DAYUSE_HOURS}}시간
                                </div>
                                <div class="list__info-price info-price" v-if="item.AVAIL_YN_DU === 'N'">
                                    <div class="info-price__current">
                                        <span class="grey1">예약마감</span>
                                    </div>
                                </div>
                                <div class="list__info-price info-price" v-else>
                                    <div class="info-price__sale" v-if="item.DAYUSE_SalePer > 0">
                                        <strong v-if="item.DAYUSE_SalePer > 0">{{item.DAYUSE_SalePer + "%"}}</strong>
                                        <span>{{item.STD_DAYUSE}}</span>
                                    </div>
                                    <div class="info-price__current">
                                        <strong>{{item.SALES_DAYUSE}}</strong>원
                                    </div>
                                </div>
                            </li>
                            <li v-if="item.S_SALES_YN === 'Y'">
                                <div class="list__info-type">
                                    <span class="list__badge list__badge--strong">숙박</span>
                                    {{item.STAY_STIME}}시 부터
                                </div>
                                <div class="list__info-price info-price" v-if="item.AVAIL_YN === 'N'">
                                    <div class="info-price__current">
                                        <span class="grey1">예약마감</span>
                                    </div>
                                </div>
                                <div class="list__info-price info-price" v-else>
                                    <div class="info-price__sale" v-if="item.AMT_SalePer > 0">
                                        <strong v-if="item.AMT_SalePer > 0">{{item.AMT_SalePer + "%"}}</strong>
                                        <span>{{item.STD_AMT}}</span>
                                    </div>
                                    <div class="info-price__current">
                                        <strong>{{item.SALES_AMT}}</strong>원
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="getevent" v-if="item.PROMO_INFO">
                            <div class="getevent__coupon">단골쿠폰</div>
                            <div class="getevent__wrap">
                                <div class="getevent__title">
                                    이벤트
                                </div>
                                <p class="getevent__description ellipsis">
                                    {{item.PROMO_DESC}}
                                </p>
                            </div>
                        </div>
                    </div>
                </router-link>
                <ul class="item__menu">
                    <li>
                        <i class="icon icon-pick--light" v-if="item.MYPICK_YN === 'N'" @click="() => pick(index, item.HOTEL_NO)">쿠폰</i> 
                        <i class="icon icon-pick--point" v-else @click="() => unpick(index, item.HOTEL_NO, item.SEQ ? item.SEQ : item.MYPICK_SEQ)">쿠폰</i>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
	<MessagePickunset v-if="openMessagePickunset" :handleClose="()=>openMessagePickunset = false" :handleSubmit="unPickAction"/>
	<MessageLogin v-if="!isLogin && openLogin" :handleClose="()=> openLogin=false" :handleSubmit="handleLoginSubmit"/>
    <TheTop />
</template>
<script>
import { computed, watch, onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { ref } from 'vue'
import MessagePickunset from '@/components/layers/Message_pickunset';
import MessageLogin from '@/components/layers/Message_login';
import { useRouter } from 'vue-router';
export default {
    components: {
		MessageLogin,
        MessagePickunset,
    },
    props: ['list', 'sortMode'],
    
    setup(props) {
        const router = useRouter();
		const store = useStore();
		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
        
		const openLogin = ref(false);
        const openMessagePickunset = ref(false);
        const unPickAction = ref(()=>{});

        const savedList = ref([]);
        const computedList = ref([]);

		onMounted(() => {
			try {
				store.dispatch("app/requestLocation");
			} catch(e) {
				navigator.geolocation.getCurrentPosition(
					function(position) {
					var posi_lat = position.coords.latitude;
					var posi_longi = position.coords.longitude;
					store.commit("app/SET_LOCATION", {
						location: {
							lat: posi_lat,
							lng: posi_longi
						}
					});
					}, 
				);
			}
        })

        const pick = (index, hotel_no) => {
            if(!isLogin.value) {
                openLogin.value = true;
                return;
            }
            computedList.value[index].MYPICK_YN = 'Y';
            store.dispatch("mmy01/postMmy0120", { proc_cd: '10', prod_flag: '01', mem_id:  userData.value.mem_token, hotel_no });
        }
        
        const handleLoginSubmit = () => {
			router.push("/layers/login");
        }

        const unpick = (index, hotelNo, seq) => {
            openMessagePickunset.value = true;
            unPickAction.value = () => {
                if(seq) {
                    store.dispatch("mmy01/fetchMmy01201", { proc_cd: '01', mem_id: userData.value.mem_token, seq })
                    .then(() => {
                        computedList.value[index].MYPICK_YN = 'N';
                    });
                } else {
                    store.dispatch("mrm02/fetchRoomDetail", { hotelNo, memId: userData.value.mem_token })
                    .then(() => {
                        store.dispatch("mmy01/fetchMmy01201", { proc_cd: '01', mem_id: userData.value.mem_token, seq: store.state.mrm02.hotel[hotelNo][0].MYPICK_SEQ });
                    })
                    .then(() => {
                        computedList.value[index].MYPICK_YN = 'N';
                    });
                }
                openMessagePickunset.value = false;
            }
        };

        watch(() => props.list, (newVal) => {
            computedList.value = newVal.map((item) => {
                return {
                    ...item,
                    HOTEL_KNM :item.HOTEL_NM ?? item.HOTEL_KNM,
                    REVIEWS: item.REVIEWS.toLocaleString("en-US"),
                    STAY_STIME: item.STAY_STIME?.split(":")[0],
                    SALES_AMT: item.SALES_AMT?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    STD_AMT: item.STD_AMT?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    AMT_SalePer: (100 - (item.SALES_AMT / item.STD_AMT * 100)).toFixed(0),
                    SALES_DAYUSE: item.SALES_DAYUSE?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    STD_DAYUSE: item.STD_DAYUSE?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    DAYUSE_SalePer: (100 - (item.SALES_DAYUSE / item.STD_DAYUSE * 100)).toFixed(0),
                    HOTEL_TAG: (item.HOTEL_TAG ?? "").split(" "),
                    DISTANCE: 0
                }
            })
            savedList.value = computedList.value.slice();
        }, {immediate: true});

        watch(() => props.sortMode, async (newVal) => {
            switch (newVal) {
                case 0:
                    computedList.value = savedList.value.slice();
                    break;
                case 1:
                    computedList.value = await Promise.all(computedList.value.map(async (item) => {
                        await store.dispatch("mhm02/fetchMhm02", {
                            lati1: store.state.app.location.lat,
                            longi1: store.state.app.location.lng,
                            lati2: item.LOC_LATITUDE,
                            longi2: item.LOC_LONGITUDE,
                        });
                        return {
                            ...item,
                            DISTANCE: store.state.mhm02.mhm02[0].distance_m
                        }
                    })
                    );
                    computedList.value.sort((a, b) => {
                        return a.DISTANCE - b.DISTANCE;
                    });
                    break;
                case 2:
                    computedList.value.sort((a, b) => {
                        return parseInt(b.REVIEWS.replace(/\D/g,'')) - parseInt(a.REVIEWS.replace(/\D/g,''));
                    });
                    break;
                case 3:
                    computedList.value.sort(() => Math.random() - 0.5);
                    break;
                case 4:
                    computedList.value.sort((a, b) => {
                        return parseInt(a.SALES_DAYUSE.replace(/\D/g,'')) - parseInt(b.SALES_DAYUSE.replace(/\D/g,''));
                    });
                    break;
                case 5:
                    computedList.value.sort((a, b) => {
                        return parseInt(b.SALES_DAYUSE.replace(/\D/g,'')) - parseInt(a.SALES_DAYUSE.replace(/\D/g,''));
                    });
                    break;
                default:
                    break;
            }
        }, {immediate: true});

        return {
            handleLoginSubmit,
            isLogin,
            openLogin,
            props,
            computedList,
            pick,
            unpick,
            openMessagePickunset,
            unPickAction
        }
    }
};
</script>